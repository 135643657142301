<template>
  <v-row class="ma-0">
    <v-col
      sm="6"
      cols="12"
      class="blue darken-4 pa-7 d-flex align-items-center hv-sm-100"
    >
    </v-col>
    <v-col sm="6" cols="12" class="px-7 py-3 hv-sm-100 d-flex">
      <v-card
        class="ma-auto"
        elevation="0"
        :width="$vuetify.breakpoint.smAndUp ? '70%' : '85%'"
      >
        <v-form ref="form" class="text-center">
          <div class="headline secondary--text font-weight-bold my-2">
            Create new Admin User
          </div>
          <v-text-field
            v-model="form.name"
            label="Username*"
            class="my-5"
            required
            :rules="rules.requiredRules"
          >
          </v-text-field>
          <v-text-field
            v-model="form.email"
            label="Email*"
            type="email"
            class="my-5"
            required
            :rules="rules.emailRules"
          >
          </v-text-field>
          <!-- <v-text-field
            v-model="form.password"
            label="Password*"
            type="password"
            class="my-5"
            required
            :rules="rules.requiredRules"
          >
          </v-text-field> -->
          <v-radio-group
            v-if="isSuperAdmin"
            v-model="adminOrSuperAdmin"
            row
            >
            <v-radio
                label="Admin"
                value="0"
            ></v-radio>
            <v-radio
                label="Super Admin"
                value="1"
            ></v-radio>
            </v-radio-group>
          <v-btn class="blue darken-4 white--text my-3" @click="register"
            >register</v-btn
          >
          <v-alert
            dense
            outlined
            color="error"
            class="my-5"
            dismissible
            v-if="error"
          >
            {{ error }}
          </v-alert>
          <v-alert
            dense
            outlined
            color="success"
            class="my-5"
            dismissible
            v-if="success"
            ><v-icon color="success">mdi-checkbox-marked-circle</v-icon>
            {{ message }}
          </v-alert>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase";
import axios from "axios";
import { API_BASE_URL } from "../constants";

export default {
  name: "App",
  data: () => ({
    uid: "",
    form: {
      email: "",
      password: ""
    },
    isSuperAdmin: 0,
    adminOrSuperAdmin: "0",
    error: null,
    token: JSON.parse(localStorage.getItem("user")),
    creator: JSON.parse(localStorage.getItem("user")),
    success: null,
    message: "",
    rules: {
      requiredRules: [(v) => !!v || "This field is required"],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+/.test(v) || "E-mail must be valid",
      ],
    },
  }),
 beforeMount() {
    let userObject = JSON.parse(localStorage.getItem('user'));
    console.log('userObject', userObject);
    this.isSuperAdmin = userObject.is_super_admin;
    //console.log(this.accountAdded);
  },
  methods: {
    register() {
      this.error = null;
      var data = JSON.stringify({
        username: this.form.name,
        email: this.form.email,
        //password: this.form.password,
        roles: ["admin"],
        is_super_admin: this.adminOrSuperAdmin,
        creator: this.creator.email
      });

      console.log('data', data);
      var config = {
        method: "post",
        url: `${API_BASE_URL}/api/auth/signup`,
        headers: {
          "Content-Type": "application/json",
          "x-access-token": this.token.accessToken,
        },
        data: data,
      };

      if (this.$refs.form.validate()) {
        // send the signed in user a verification email
        axios(config).then((response) => {
          this.message = response.data.message;
          this.success = true;
        }).catch(error  => {
            console.log('inisde catch');
            if (error.response) {
                
                console.log(error.response.data.message)
                  this.error = error.response.data.message;
                
            }
        }).catch(error  => {
            
            if (error.response) {
                  this.error = error.response.data.message;
                
            }
        });
      }
    },
  },
};
</script>
<style>
.contact-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  margin: 20px;
}
</style>
