<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 purple darken-1">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Support
            </div>
          </div>
        </v-card>
      </v-col>


      <v-col cols="12"> 
            <v-card class="ma-auto" elevation="0">
                <v-form ref="form">
                    <div class="headline secondary--text font-weight-bold my-2 text-center">Submit Host Support Request</div>
                    
                    <div class="headline secondary--text my-2 text-center">Please submit the form below for any questions about your JAG account or technical support regarding your hotspot and a JAG team member will be in touch as soon as possible</div>

                    <v-alert
                          dense
                          outlined
                          type="error"
                          v-if="error"
                    >
                        {{error}}
                    </v-alert>

                    <v-alert
                            dense
                            text
                            type="success"
                            v-if="success"
                    >
                        {{success}}
                    </v-alert>

                    <div>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="form.name"
                                  label="Name*"
                                  class="my-5"
                                  required
                                  :rules="rules.requiredRules"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="form.email"
                                  label="Email Id*"
                                  type="email"
                                  class="my-5"
                                  required
                                  :rules="rules.emailRules"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="form.phone"
                                  label="Phone*"
                                  class="my-5"
                                  required
                                  :rules="rules.requiredRules"
                                >
                                </v-text-field>
                            </v-col>

                            <v-col>
                                <v-text-field v-model="form.subject"
                                  label="Subject*"
                                  class="my-5"
                                  required
                                  :rules="rules.requiredRules"
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>

                            <v-textarea v-model="form.message"
                              label="Message*"
                              auto-grow
                              rows="1"
                              type="text"
                              row-height="15"
                              autocomplete="off"
                              required
                              :rules="rules.requiredRules"
                            >
                            </v-textarea>
                        
                    </div>

                   <div class=" text-center">
                    <v-btn class="purple darken-1 white--text my-3" @click="send">send</v-btn>
                   </div>
                    
                </v-form>
            </v-card>

        </v-col>
      
    </v-row>
  </v-container>
</template>


<script>
import axios from "axios";
import VueCookies from "vue-cookies";
import {API_BASE_URL } from '../../constants';

export default {
  setup() {},
  data() {
    return {
        uid: localStorage.getItem('user-portal-uid'),
        form: {
                name:"",
                email: "",
                phone:"",
                subject:"",
                message: ""
        },
        error: null,
        success:null,
        rules: {
            requiredRules: [v => !!v || 'This field is required'],
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+/.test(v) || 'E-mail must be valid',
            ],
        },
        token: {
          accessToken: ''
        },
    };
  },

  beforeMount() {
    //this.getuid();
  },

  methods: {
    
    getuid() {
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          console.log('inside UUId');
        });
    },
    send(){
        this.error = null;
        if (this.$refs.form.validate()) {
          
          axios.post(`${API_BASE_URL}/contactus`,{name:this.form.name,email:this.form.email,phone:this.form.phone,subject:this.form.subject,message:this.form.message})
          .then((response) => {
             
             this.success="Thanks for contacting us. We'll get bact to you as soon as possible.";
             this.$refs.form.reset();

             //auto hide after 5 sec
             setTimeout(() => this.success = null, 5000)
                               
            }, (error) => {
              this.error = error.message;
              
              //auto hide after 5 sec
              setTimeout(() => this.error = null, 5000)
            });
        }
    }
  },
  
};
</script>
