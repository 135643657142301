<template>
  <v-container>
    <v-row>

      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Email Templates
            </div>
            <v-spacer></v-spacer>
           
          </div>
        </v-card>
      </v-col>

      

      <v-col md="12 " cols="12" class="my-2">
         <v-alert
          dense
          text
          type="success"
          v-if="emailTemplateUpdateSuccess"
        >
          Email template updated successfully.
        </v-alert>
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Email Template
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details

            ></v-text-field>
          </v-card-title>

          <div class="pa-2">
            <v-data-table
              :headers="headers1"
              :items="emailTemplates"
              :items-per-page="5"
              :search="search"
              
              class="elevation-1"
            >
              <template v-slot:item.ename="{ item }">
                {{item.ename}}
              </template>
              <template v-slot:item.esubject="{ item }">
                {{item.esubject}}
              </template>
               <template v-slot:item.ebody="{ item }">
                {{formatHtmlText(item.ebody)}}
              </template>
              <template v-slot:item.action="{ item }">
                <v-btn fab dark small color="warning" @click="editEmailTemplate(item)"
                ><v-icon dark> mdi-pencil-outline </v-icon></v-btn>
              </template>

            </v-data-table>
          </div>

        </v-card>
      </v-col>

      <v-dialog
          v-model="editTemplate"
          persistent
          max-width="1200px"
          height="1000px">
        <v-card>
          <v-card-title class="headline blue darken-4 white--text">
            Edit Email Template
          </v-card-title>
          <v-form ref="form1" class="mx-2" lazy-validation>
            <v-card-text class="pa-5">
              <div>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="ename"
                      filled
                      clearable
                      label="Name"
                      type="text"
                      :rules="nameRules"
                    ></v-text-field> </v-col
                  ><v-col>
                    <v-text-field
                      v-model="esubject"
                      filled
                      clearable
                      label="Subject"
                      type="text"
                      :rules="nameRules"
                    ></v-text-field></v-col
                  >
                  
                </v-row>
                <v-row>
                  <v-col>
                    <p>Variables : {{this.evariables}}</p>
                    <p>Email Body</p>
                    <vue-ckeditor 
                      v-model="ebody"
                      filled
                      clearable
                      label="Email Body"
                      type="text"
                      :rules="nameRules"
                      :config="config" 
                       />
                   </v-col>
                </v-row>
               
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn text dark color="red" @click="closingRequest()">
                    Close
                  </v-btn>
                  <v-btn
                    text
                    dark
                    color="success"
                    @click="saveEmailTemplate(templateID)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>

              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>

    
    </v-row>
  </v-container>
</template>



<script>
import VueCkeditor from 'vue-ckeditor2';

import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";

export default {
  setup() {},
  components: { VueCkeditor },
  data() {
    return {
      nameRules: [(v) => !!v || "Required"],
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      emailTemplates: {},
      editTemplate:false,
      emailTemplateUpdateSuccess:false,
      token: JSON.parse(localStorage.getItem("user")),
      search: "",
      uid: "",
      attrs: '',
      on: '',
      templateID:0,
      ename:"",
      esubject:"",
      evariables: "N/A",
      ebody:"",
      content: '',
      config: {
        toolbarGroups: [
            { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
            { name: 'forms', groups: [ 'forms' ] },
            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            { name: 'paragraph', groups: [ 'list',  'align',  'paragraph' ] },
            
            { name: 'styles', groups: [ 'styles' ] },
            { name: 'colors', groups: [ 'colors' ] },
            { name: 'others', groups: [ 'others' ] }
          ],
        height: 300
      }
    };
  },
  computed: {
    headers1() {
      
      return[
          { text: 'Name',value:'ename'},
          { text: 'Subject', value: 'esubject' },
          { text: 'Email Body', value: 'ebody' },
          { text: 'Action', value: 'action' },
        ]
    }
  },
  beforeMount() {
    //this.getuid();

    this.getEmailTemplates();
  },

  methods: {
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    openUser(userid) { 
      this.$router.push({ name: "user", params: { id: userid } });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getEmailTemplates(){
      this.$root.isLoading = true;
       this.emailTemplates = [];
        axios
        .get(`${API_BASE_URL}/emailtemplate`, {
          headers: {  accept: "application/json",
            "x-access-token": this.token.accessToken, },
        })
        .then((resp) => {
          console.log(resp.data);
          this.emailTemplates = resp.data;
          this.$root.isLoading = false;
        });

     
    },
    saveEmailTemplate(templateID){
       
       this.$refs.form1.validate();

       if (!this.ename || !this.esubject || !this.ebody) return false;
        console.log("templateID = ",templateID)

        let payload = {
          ename: this.ename,
          esubject: this.esubject,
          ebody: this.ebody,
        };

        console.log(payload)

        axios.put(`${API_BASE_URL}/editemailtemplate/${templateID}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          
          this.emailTemplateUpdateSuccess = true;
          setTimeout(()=>{
            this.emailTemplateUpdateSuccess=false;
          },5000);

          this.closingRequest();
          this.getEmailTemplates();
        });
     
    },
    editEmailTemplate(rowdet){
      this.editTemplate = true;

      console.log('rowdet', rowdet);

      (this.templateID = rowdet.ID),
      (this.ename = rowdet.ename),
      (this.esubject = rowdet.esubject),
      (this.ebody = rowdet.ebody);
      this.evariables = (rowdet.variables) ? rowdet.variables : 'N/A';
    },
    closingRequest(){
      this.editTemplate = false;
      (this.ename = ''),
      (this.esubject = ''),
      (this.ebody ='');
      this.evariables = 'N/A';
    },
    formatHtmlText(str) {
      if ((str===null) || (str===''))
      return false;
      else
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
   }
  },
};
</script>