<template>
  <v-container>

    <v-dialog v-model="editReq" persistent max-width="1200px" height="1000px">
      <v-card>
        <v-card-title class="headline blue darken-4 white--text">
          Edit request
        </v-card-title>
        <v-card-text class="pa-5">
          <div>
            <v-row>Amount Allowed To Withdraw : {{withdrawalLimit}}</v-row>
            <v-row
              ><v-autocomplete
                :items="useremail"
                label="User email"
                v-model="requser"
              ></v-autocomplete>
            </v-row>
            <v-row>
              amount
              <input
                v-model="amount"
                color="#474DFF"
                filled
                clearable
                type="number"
              />
              <v-select
                :items="items"
                label="Payment method"
                v-model="message"
              ></v-select>

              <p v-if="message != 'HNT'">Amount in HNT</p>
              <input
                v-if="message != 'HNT'"
                filled
                clearable
                type="number"
                label="Amount in HNT"
                v-model="HNTchanges"
              />
            </v-row>
            <v-row> 
              <v-select
                :items="status"
                label="Payment status"
                v-model="paymentStatus"
              >
              </v-select>
            </v-row>
            <v-row v-if="withdrawalLimitExceded" style="margin-top: 24px">
              <v-alert type="error" transition="fade-transition">
                Entered amount is greated than the allowed withdrawal limit.
              </v-alert>
            </v-row>
            <v-row v-if="responseMessage != ''" style="margin-top: 24px">
              <v-alert dismissible type="info">
                  {{responseMessage}}
              </v-alert>
            </v-row>
            <v-card-actions class="pt-0">
              <v-btn
                text
                dark
                color="red"
                @click="deleteRequest(reqid)"
              >
                delete permanently 
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn text dark color="red" @click="closingRequest()">
                Close
              </v-btn>
              <v-btn
                text
                dark
                color="success"
                @click="editRequest(reqid, paymentStatus)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialog" max-width="1200px" height="1000px">
      <v-card>
        <v-card-title class="headline blue darken-4 white--text">
          Manual request
        </v-card-title>
             <v-form ref="form" class="mx-2" lazy-validation>
        <v-card-text class="pa-5">
          <div>
            <v-row>Amount Allowed To Withdraw : {{withdrawalLimit}}</v-row>
            <v-row>
              <v-autocomplete
                :items="useremail"
                label="User email"
                v-model="requser"
                :rules="nameRules"
                @change="selectedUser();"
              ></v-autocomplete>
            </v-row>
            <v-row>
              <v-text-field
                v-model="amount"
                filled
                clearable
                type="number"
                label="Amount in HNT"
                :rules="nameRules"
              >
              </v-text-field>
              <v-select
                :items="items"
                label="Payment method"
                v-model="message"
                 :rules="nameRules"
              >
              </v-select>
            </v-row>
            <v-row> 
              <v-select
                :items="status"
                label="Payment status"
                v-model="paymentStatus"
                :rules="nameRules"
              >
              </v-select>
            </v-row>
            <v-row v-if="withdrawalLimitExceded" style="margin-top: 24px">
              <v-alert type="error" transition="fade-transition">
                Entered amount is greated than the allowed withdrawal limit.
              </v-alert>
            </v-row>
            
            <v-card-actions class="pt-0">
              <v-spacer></v-spacer>
              <v-btn text dark color="red" @click="closeManualReqestDailog()">
                Close
              </v-btn>
              <v-btn
                text
                dark
                color="success"
                @click="sendRequest(requser, amount, paymentStatus, message)"
              >
                Save
              </v-btn>
            </v-card-actions>
          </div>
        </v-card-text>
             </v-form>
      </v-card>
    </v-dialog>

    <v-dialog v-model="reject" :retain-focus="false" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure to reject?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="reject = false">
            NO
          </v-btn>
          <v-btn
            color="error"
            text
            @click="openRejectionModal()"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="approve" :retain-focus="false" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure to Approve?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" text @click="approve = false">
            NO
          </v-btn>
          <v-btn color="success" text @click="approved()">
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Pending Requests
            </div>
            <v-btn outlined dark small @click="viewManualRequestDailog()">
              Manual request
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Pending Activity
            <v-spacer></v-spacer>
            <v-text-field
              v-model="pendingSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <div class="pa-2">
            
            <v-data-table
              :headers="pendingTableHeader"
              :items="pendingRequests"
              :search="pendingSearch"
              item-key="ID"
            >
              <template v-slot:[`item.action`]="{ item }">
                <v-btn color="success" @click="showApproveConfirmation(item.ID)">
                  Approve 
                </v-btn>
                <v-btn color="error" @click="showRejectConfirmation(item.ID)">
                  Reject
                </v-btn>
                <!-- <v-btn fab small color="warning" @click="viewRequest(item.ID)">
                  <v-icon dark> mdi-pencil-outline </v-icon>
                </v-btn> -->
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Approved / Completed Requests
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Approved / Completed Activity
            <v-spacer></v-spacer>
            <v-text-field
              v-model="approvedSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <div class="pa-2">
            
            <v-data-table
              :headers="approvedTableHeader"
              :items="approvedRequests"
              :search="approvedSearch"
              item-key="ID"
            >
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Rejected Requests
            </div>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Rejected Activity
            <v-spacer></v-spacer>
            <v-text-field
              v-model="rejectedSearch"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            >
            </v-text-field>
          </v-card-title>
          <div class="pa-2">
            
            <v-data-table
              :headers="rejectedTableHeader"
              :items="rejectedRequests"
              :search="rejectedSearch"
            >
              <template v-slot:item.action>
                N/A
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>

    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog persistent width="500" v-model="rejectionModal">
      <v-card>

        <v-card-title class="headline purple darken-1 white--text">
          Reason for rejecting the Request
        </v-card-title>

        <!-- 2fa using email -->
        <v-card-text class="pa-3 2fa_email_code_email">
          <div>
            <div>
              <div align="center" justify="center">
                <template>
                   <v-textarea
                      v-model="rejectionReason"
                      label="Rejection reason."
                      rows="2"
                    ></v-textarea>
                </template>
              </div>

              <div>
                <v-btn color="success" @click="rejecttransaction()">Submit 
                </v-btn>
              </div>
              

              <div v-if="rejectionError" align="center" justify="center" style="margin-top: 24px">
                <v-alert type="error" transition="fade-transition">
                  Please enter rejection reason.
                </v-alert>
              </div>

            </div>
          </div>
        </v-card-text>
        </v-card>
      </v-dialog>

  </v-container>
</template>

<script>
/* eslint-disable */
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import {API_BASE_URL, API_HELIUM_URL, API_HELIUM_NODE_JAG_URL} from '../constants';
import moment from "moment";
export default {
  data() {
    return {
      items: ["HNT"],//"ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"
      message: "",
      amount: "",
      reject: false,
      approve: false,
      editReq: false,
      snackbar: true,
      responseMessage:"",
      text: 'Save Successful',
      timeout: 4000,
      hotspotDetails: [],
      hotspotTotal: [],
      url: "",
      dialog: false,
      users: {},
      HNTchanges: 0,
      reqid: 0,
      useremail: {},
      requser: "",
      mapurl: "",
      paymentStatus: "",
      token: JSON.parse(localStorage.getItem("user")),
      start: "",
      today: "",
      status: ["Pending", "Approved"],
      uid: "",
      pendingSearch: "",
      approvedSearch: "",
      completedSearch: "",
      rejectedSearch: "",
      oracleprice: null,
      installation: "",
      requestLog: [],
      maxearn: 0,
      selected: [],
      allRequests: [],
      pendingRequests: [],
      approvedRequests: [],
      completedRequests: [],
      rejectedRequests: [],
      filters: {
        email: [],
        payment: [],
        flag: [],
      },
      selectedRejectId: "",
      selectedApproveId: "",
      withdrawalLimit: 0,
      userEarnedAmount: 0,
      withdrawalLimitExceded: false,
      rules: {
        requiredRules: [(v) => !!v || "This field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
      nameRules: [(v) => !!v || "This field is required"],
      rejectionReason: '',
      rejectionModal: false,
      rejectionError: false
    };
  },
  computed: {
    pendingTableHeader() {
      return [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Requested Amount", value: "amount" },
        {
          text: "Request Date",
          align: "start",
          value: "entered",
        },
        { text: "Payment Method", value: "payment" },
        {
          text: "Status",
          value: "flag",
        },
        { text: "Actions", value: "action", sortable: false, align: "center" },
      ];
    },
    approvedTableHeader() {
      return [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "HNT Paid Out", value: "amount" },
        {
          text: "Request Date",
          align: "start",
          value: "entered",
        },
        {
          text: "Approved Date",
          align: "start",
          value: "processed",
        },
        { text: "Payment Method", value: "payment" },
        {
          text: "Status",
          value: "flag",
        },
      ];
    },
    rejectedTableHeader() {
      return [
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Requested Amount", value: "amount" },
        {
          text: "Request Date",
          align: "start",
          value: "entered",
        },
        {
          text: "Rejected Date",
          align: "start",
          value: "processed",
        },
        { text: "Payment Method", value: "payment" },
        {
          text: "Status",
          value: "flag",
        },
        {
          text: "Reason",
          value: "rejection_reason",
        },
      ];
    },
    filteredDesserts() {
      return this.requestLog.filter((d) => {
        return Object.keys(this.filters).every((f) => {
          return this.filters[f].length < 1 || this.filters[f].includes(d[f]);
        });
      });
    },
  },
  beforeMount() {
    //this.getuid();
    var start = new Date();
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var m2 = today.getMonth() - 2;
    var yyyy = today.getFullYear();
    var yyyy2 = today.getFullYear() - 1;
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    if (m2 < 10) {
      m2 = "0" + m2;
    }
    this.today = yyyy + "-" + mm + "-" + dd;
    this.start = yyyy2 + "-" + m2 + "-" + dd;
    this.getRequest();
    //this.hotspotDet("112Z69CrW1sTv6BDTv23Hx8th6vUiuAK6zyEviJdpXrd7Dmb4Awz", this.start, this.today);
    this.getOracleValue();
  },
  methods: {
    viewManualRequestDailog() {
      this.dialog = true;
    },
    showRejectConfirmation(selectedId) {
      this.selectedRejectId = selectedId;
      this.reject = true;
    },
    showApproveConfirmation(selectedId) {
      this.selectedApproveId = selectedId;
      this.approve = true;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.requestLog.slice();
    },
    columnValueList(val) {
      return this.requestLog.map((d) => d[val]);
    },
    async fetchSelectedUserEarning(firebaseId) {
      // Fetching selected user earning
      return axios.get(`${API_HELIUM_NODE_JAG_URL}/v1/user/hotspots/earning/${firebaseId}`, {
          headers: {
            accept: "application/json",
          },
      });
    },
    selectedUser() {
      this.withdrawalLimitExceded = false;
      this.$root.isLoading = true;
      let userRequestedAmount = 0;
      this.userEarnedAmount = 0;
      this.withdrawalLimit = 0;
      // Fetching selected user info to get firebaseID
      let userDetails = this.users.find((user) => {
        return user.email === this.requser
      });
      // Fetching selected user requested amount 
      this.allRequests.map((request) => {
        if(request.email == this.requser && request.flag !== 'Rejected') {
          userRequestedAmount += parseFloat(request.amount);
        }
      });
      //Fetching selected user earning
      this.fetchSelectedUserEarning(userDetails.firebaseID).then((result) => {
        this.userEarnedAmount = result.data[0].totalEarning.toFixed(2);
        let allowedWithdrawal = (this.userEarnedAmount - userRequestedAmount).toFixed(2);
        this.withdrawalLimit = ( allowedWithdrawal > 0) ? allowedWithdrawal : 0;
        this.$root.isLoading = false;
      });
      
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    sendRequest(user, amount, status, message) {
      if(!this.$refs.form.validate()) {
        return false;
      }
      console.log('amount before', amount);
      amount = parseFloat(amount);
      console.log('amount after', amount);
      console.log('this.withdrawalLimit', this.withdrawalLimit);
      if(amount > this.withdrawalLimit) {
        this.withdrawalLimitExceded = true;
        return false;
      }
      let details = this.users.find((o) => o.email === user);
      if (status == "Pending") {
        status = 0;
      } else if (status == "Completed") {
        status = 1;
      } else if (status == "Rejected") {
        status = 2;
      } else if (status == "Approved") {
        status = 3;
      }
      axios.post(`${API_BASE_URL}/user/request/${details.firebaseID}`, {
        payment: this.message,
        amount: this.amount,
        flag: status,
        HNTchanges: this.HNTchanges,
        totalMined: this.userEarnedAmount,
      })
      .then((resp) => {
        this.text = "Request saved successfully";
        this.snackbar = true
        this.getRequest();
        this.closeManualReqestDailog();
      });
    },
    closeManualReqestDailog() {
      this.dialog = false;
      this.withdrawalLimitExceded = false;
      this.message = "";
      this.amount = 0;
      this.withdrawalLimit = 0;
      this.HNTchanges = 0;
      this.requser = "";
    },
    viewRequest(ID) {
      this.reqid = ID;
      this.responseMessage="";
      this.editReq = true;
      this.$root.isLoading = true;
      let userRequestedAmount = 0;
      this.userEarnedAmount = 0;
      this.withdrawalLimit = 0;
      axios.get(`${API_BASE_URL}/request/${ID}`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        let details = this.users.find(
          (o) => o.firebaseID === resp.data[0].firebaseID
        );
        this.requser = details.email;
        this.amount = resp.data[0].amount;
        this.HNTchanges = resp.data[0].HNTchange;
        this.message = resp.data[0].payment;
        if (resp.data[0].flag == 0) {
          this.paymentStatus = "Pending";
        } else if (resp.data[0].flag == 1) {
          this.paymentStatus = "Completed";
        } else if (resp.data[0].flag == 2) {
          this.paymentStatus = "Rejected";
        } else if (resp.data[0].flag == 3) {
          this.paymentStatus = "Approved";
        }
        // Fetching selected user requested amount 
        this.allRequests.map((request) => {
          if(request.email == this.requser && request.flag !== 'Rejected' && request.ID != ID) {
            userRequestedAmount += parseFloat(request.amount);
          }
        });
        
        //Fetching selected user earning
        this.fetchSelectedUserEarning(details.firebaseID).then((result) => {
          this.$root.isLoading = false;
          this.userEarnedAmount = result.data[0].totalEarning.toFixed(2);
          let allowedWithdrawal = (this.userEarnedAmount - userRequestedAmount).toFixed(2);
          this.withdrawalLimit = ( allowedWithdrawal > 0) ? allowedWithdrawal : 0;
        });
      });
    },
    editRequest(ID, status) {
      this.editReq = true;
      console.log('amount before', this.amount);
      this.amount = parseFloat(this.amount);
      console.log('amount after', this.amount);
      console.log('this.withdrawalLimit', this.withdrawalLimit);
      if(this.amount > this.withdrawalLimit) {
        this.withdrawalLimitExceded = true;
        return false;
      }
      if (status == "Pending") {
        status = 0;
      } else if (status == "Completed") {
        status = 1;
      } else if (status == "Rejected") {
        status = 2;
      } else if (status == "Approved") {
        status = 3;
      }
      let details = this.users.find((o) => o.email === this.requser);
      let payload = {
        payment: this.message,
        requser: details.firebaseID,
        amount: this.amount,
        flag: status,
        HNTchanges: this.HNTchanges,
      };
      axios.put(`${API_BASE_URL}/request/${ID}`, payload, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.getRequest();
        this.withdrawalLimitExceded = false;
        this.withdrawalLimit = 0;
        this.userEarnedAmount = 0;
        this.message = "";
        this.amount = 0;
        this.HNTchanges = 0;
        this.requser = "";
        this.responseMessage = "Request saved successfully.";
        setTimeout(()=>{
          this.editReq = false;
        },5000);
      });
    },
    closingRequest() {
      this.message = "";
      this.amount = 0;
      this.HNTchanges = 0;
      this.requser = "";
      this.editReq = false;
    },
    deleteRequest(ID) {
      axios
        .delete(`${API_BASE_URL}/request/${ID}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          console.log("permanent deleted");
          
          
          
          this.responseMessage = "Request deleted successfully.";
          this.withdrawalLimitExceded = false;
          this.withdrawalLimit = 0;
          this.userEarnedAmount = 0;
          setTimeout(()=>{
            this.editReq = false;
          },5000);
          this.getRequest();
        });
    },
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    hotspotDet(address, start, today) {
      (this.dialog = false),
        axios
          .get(`${API_HELIUM_URL}/v1/hotspots/${address}`, {
            headers: { accept: "application/json" },
          })
          .then((resp) => {
            this.hotspotDetails = resp.data;
            var [year, month, day] = this.hotspotDetails.data.timestamp_added
              .substring(0, 10)
              .split("-");
            this.installation = new Date(year, month, day);
            axios
              .get(
                `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${this.installation
                  .toISOString()
                  .slice(0, 10)}`,
                {
                  headers: { accept: "application/json" },
                }
              )
              .then((resp) => {
                this.hotspotTotal = resp.data;
                this.mapurl = [
                  "https://www.openstreetmap.org/export/embed.html?bbox=" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng +
                    "%2C" +
                    this.hotspotDetails.data.lat +
                    "&layer=mapnik&marker=" +
                    this.hotspotDetails.data.lat +
                    "%2C" +
                    this.hotspotDetails.data.lng,
                ].join("");
                this.maxearn = parseInt(
                  ((this.hotspotTotal.data.total / 100) * 20).toFixed(2)
                );
              });
          });
    },
    getRequest() {
      this.$root.isLoading = true;
      axios.get(`${API_BASE_URL}/users`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.users = resp.data;
        this.useremail = this.users.map(function (v) {
          return v["email"];
        });
        this.$root.isLoading = false;
      }).catch(error  => {
              this.$root.isLoading = false;
          });
      axios.get(`${API_BASE_URL}/requests`, {
        headers: {
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        },
      })
      .then((resp) => {
        this.pendingRequests = [];
        //this.completedRequests = [];
        this.rejectedRequests = [];
        this.approvedRequests = [];
        let requestsResponse = resp.data;
        this.allRequests = resp.data;
        if(requestsResponse.length > 0) {
          requestsResponse.forEach((element, index) => {
            element.entered = moment(element.entered).format('MM-DD-YYYY | HH:mm:ss');
            if(element.processed) {
              element.processed = moment(element.processed).format('MM-DD-YYYY | HH:mm:ss');
            } else {
              element.processed = 'N/A';
            }
            if(element.payment === "HNT") {
              element.HNTchange = element.amount;
            }
            switch(element.flag) {
              case 0:
                element.flag = "Pending";
                this.pendingRequests.unshift(element);
                break;
              case 2:
                element.flag = "Rejected";
                this.rejectedRequests.unshift(element);
                break;
              case 3:
                //case 1:
                element.flag = "Approved";
                this.approvedRequests.unshift(element);
                break;    
            }
          });
        }
      });
    },
    rejecttransaction() {
      console.log('this.rejectionReason', this.rejectionReason);
       this.rejectionError = false;

       if(!this.rejectionReason){
          this.rejectionError = true;
          return false;
       }

      let payload = { flag: 2, rejectionReason: this.rejectionReason };
      axios.post(`${API_BASE_URL}/requeststatus/${this.selectedRejectId}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.reject = false;
          this.text = "Request rejected successfully"
          this.snackbar=true;
          this.rejectionModal = false;
          this.rejectionReason = '';
          this.getRequest();
        });
    },
    approved() {
      let payload = { flag: 3 };
      axios
        .post(`${API_BASE_URL}/requeststatus/${this.selectedApproveId}`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.approve = false;
          this.text = "Request approved successfully";
          this.snackbar=true
          this.getRequest();
        }).catch(error => {
          let message = typeof error.response !== "undefined" ? error.response.data.message : error.message;
          alert(message);
          this.approve = false;
          this.snackbar=true
          this.getRequest();
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    saveHotspot(address) {
      let payload = { haddress: address };
      let res = axios.put(
        `${API_BASE_URL}/hotspot/${this.uid}`,
        payload
      );
      let data = res.data;
    },
    openRejectionModal(){
      this.reject = !this.reject;
      this.rejectionModal = !this.rejectionModal;
    }
  },
};
</script>