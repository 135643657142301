<template>
  <v-container>
    <v-row>

      <v-col cols="12">
         <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Manage Roles
            </div>
            <v-btn outlined dark small @click="createRole()">
              Create Role
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Manage Roles
            <v-spacer></v-spacer>
            <!-- <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field> -->
          </v-card-title>

          <div class="pa-2">
            <v-data-table
              :headers="headers1"
              :items="emailTemplates"
              :items-per-page="5"

              class="elevation-1"
            >
              <template v-slot:item.name="{ item }">
                {{item.name}}
              </template>
              
              <template v-slot:item.action="{ item }">
                <v-btn v-if="item.id!=1" fab dark small color="warning" @click="editRole(item)"
                ><v-icon dark> mdi-pencil-outline </v-icon></v-btn>
              </template>

            </v-data-table>
          </div>

        </v-card>
      </v-col>

      <v-dialog
          v-model="createTemplate"
          persistent
          max-width="1200px"
          height="1000px">
        <v-card>
          <v-card-title class="headline blue darken-4 white--text">
            Create Role
          </v-card-title>
          <v-form ref="form5" class="mx-2" lazy-validation>
            <v-card-text class="pa-5">
              <div>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="name"
                      filled
                      clearable
                      label="Role Name"
                      type="text"
                      :rules="nameRules"
                    ></v-text-field> </v-col
                  >
                  
                </v-row>
                <v-row>
                  <v-col>
                    <ul>
                      <li><input type="checkbox" class="form-control"  value="Account/Hotspot" v-model="permissions"> <label>Account/Hotspot</label></li>
                      <li><input type="checkbox" class="form-control"  value="User Management" v-model="permissions"> <label>User Management</label></li>
                      <li><input type="checkbox" class="form-control"  value="Reports" v-model="permissions"> <label>Reports</label></li>
                      <li><input type="checkbox" class="form-control"  value="Request" v-model="permissions"> <label>Request</label></li>
                      <li><input type="checkbox" class="form-control"  value="Email Template" v-model="permissions"> <label>Email Template</label></li>
                      <li><input type="checkbox" class="form-control"  value="Email" v-model="permissions"> <label>Email </label></li>
                      <li><input type="checkbox" class="form-control"  value="Admin User" v-model="permissions"> <label>Admin User </label></li>
                      <li><input type="checkbox" class="form-control"  value="Manage Roles" v-model="permissions"> <label>Manage Roles </label></li>
                      
                    </ul>
                    
                   
                    </v-col
                  >
                </v-row>
         
               
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn text dark color="red" @click="closingRequest()">
                    Close
                  </v-btn>
                  <v-btn
                    text
                    dark
                    color="success"
                    @click="saveEmailTemplate(templateID)"
                  >
                    Save
                  </v-btn>
                </v-card-actions>

              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
      <v-dialog
          v-model="editTemplate"
          persistent
          max-width="1200px"
          height="1000px">
        <v-card>
          <v-card-title class="headline blue darken-4 white--text">
            Edit Role
          </v-card-title>
          <v-form ref="form6" class="mx-2" lazy-validation>
            <v-card-text class="pa-5">
              <div>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="name"
                      filled
                      clearable
                      label="Role Name"
                      type="text"
                      :rules="nameRules"
                    ></v-text-field> </v-col
                  >
                  
                </v-row>
                <v-row>
                  <v-col>
                    <ul>
                      <li><input type="checkbox" class="form-control"  value="Account/Hotspot" v-model="permissions"> <label>Account/Hotspot</label></li>
                      <li><input type="checkbox" class="form-control"  value="User Management" v-model="permissions"> <label>User Management</label></li>
                      <li><input type="checkbox" class="form-control"  value="Reports" v-model="permissions"> <label>Reports</label></li>
                      <li><input type="checkbox" class="form-control"  value="Request" v-model="permissions"> <label>Request</label></li>
                      <li><input type="checkbox" class="form-control"  value="Email Template" v-model="permissions"> <label>Email Template</label></li>
                      <li><input type="checkbox" class="form-control"  value="Email" v-model="permissions"> <label>Email </label></li>
                      <li><input type="checkbox" class="form-control"  value="Admin User" v-model="permissions"> <label>Admin User </label></li>
                      <li><input type="checkbox" class="form-control"  value="Manage Roles" v-model="permissions"> <label>Manage Roles </label></li>
                      
                    </ul>
                    
                   
                    </v-col
                  >
                </v-row>
         
               
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>

                  <v-btn text dark color="red" @click="closingRequest()">
                    Close
                  </v-btn>
                  <v-btn
                    text
                    dark
                    color="success"
                    @click="updateEmailTemplate()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>

              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>

    
    </v-row>
  </v-container>
</template>



<script>


import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";

export default {
  setup() {},
  data() {
    return {
  permissions:[],
nameRules: [(v) => !!v || "Required"],
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
     
      emailTemplates: [{"name":'Super Admin',"action":"action"}],
      editTemplate:false,
      createTemplate:false,
      token: JSON.parse(localStorage.getItem("user")),
      search: "",
      uid: "",
      attrs: '',
      on: '',
      templateID:0,
      ename:"",
      name:"",
      esubject:"",
      ebody:"",
      content: '',
      config: {
        toolbarGroups: [
            { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
            { name: 'forms', groups: [ 'forms' ] },
            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            { name: 'paragraph', groups: [ 'list',  'align',  'paragraph' ] },
            
            { name: 'styles', groups: [ 'styles' ] },
            { name: 'colors', groups: [ 'colors' ] },
            { name: 'others', groups: [ 'others' ] }
          ],
        height: 300
      }
    };
  },
  computed: {
    headers1() {
      
      return[
          { text: 'Name',value:'name'},
          { text: 'Action', value: 'action' },
        ]
    }
  },
  beforeMount() {
    //this.getuid();

    this.getEmailTemplates();
  },

  methods: {
    getuid() {
      //this.$cookies.set('uid', this.user.data.uid)
      //this.uid = this.user.data.uid
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    openUser(userid) {
      this.$router.push({ name: "user", params: { id: userid } });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getEmailTemplates(){

      this.$root.isLoading = true;
       this.emailTemplates = [];
        axios
        .get(`${API_BASE_URL}/admin/get-roles`, {
          headers: {  accept: "application/json",
            "x-access-token": this.token.accessToken, },
        })
        .then((resp) => {
          console.log(resp.data)
          this.$root.isLoading = false;
          this.emailTemplates = resp.data;
        }).catch((err) => {
          console.log(err);
          this.$root.isLoading = false;
        });

     
    },
    saveEmailTemplate(templateID){
       this.$refs.form5.validate();

       if (!this.name)
       {
         return false;
       }

       
      // //   console.log("templateID = ",templateID)

        let payload = {
          name: this.name,
          permissions: this.permissions,
          admin: this.token.email
        };

      // //   console.log(payload)

        axios.post(`${API_BASE_URL}/create-roles`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.closingRequest();
          this.getEmailTemplates();
        });
     
    },
    updateEmailTemplate(){
       this.$refs.form6.validate();

       if (!this.name)
       {
         return false;
       }

       
      // //   console.log("templateID = ",templateID)

        let payload = {
          roleid: this.templateID,
          name: this.name,
          permissions: this.permissions,
          admin : this.token.email
        };

      // //   console.log(payload)

        axios.post(`${API_BASE_URL}/update-roles`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.closingRequest();
          this.getEmailTemplates();
        });
     
    },
    editRole(item) {
      this.editTemplate = true;
      var  role_id = item.id;
      this.name = item.name;
      this.templateID = item.id;
  
       axios
        .get(`${API_BASE_URL}/user/permissions/${role_id}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.permissions = [];
          resp.data.forEach(element => {

            this.permissions.push(element.permission);
           
          });
          console.log(this.permissions);
        });
    },
    createRole(item){

      this.permissions = [];
      this.name="";
      this.createTemplate = true;
    },
    closingRequest(){
      this.editTemplate = false;
      this.createTemplate = false;
      (this.ename = ''),
      (this.esubject = ''),
      (this.ebody ='');
    },
    formatHtmlText(str) {
      if ((str===null) || (str===''))
      return false;
      else
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
   }
  },
};
</script>