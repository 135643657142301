<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Logs
            </div>
            <v-spacer></v-spacer>
            
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="100%">
          <v-alert type="success" v-if="logDeleteSuccessFlag">
              Logs deleted successfully.
            </v-alert>
            <v-alert type="error" v-if="logDeleteFailureFlag">
              Opps, Something went wrong.
            </v-alert>
          <v-row class="pa-6">
            <v-col class="d-flex" sm="3">
              <v-autocomplete
                :items="typesArr"
                label="Module"
                dense
                outlined
                v-model="filter_module"
                item-text="text"
                item-value="value"
              >
              </v-autocomplete>
            </v-col>
            <!-- <v-col class="d-flex" sm="3">
              <v-autocomplete
                :items="status"
                label="Execution Status"
                dense
                outlined
                v-model="filter_status"
              >
              </v-autocomplete>
            </v-col> -->
            <v-col class="d-flex" sm="2">
              <v-menu
                ref="startDateMenu"
                v-model="startDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedStartDate"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :max="nowDate"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex" sm="2">
              <v-menu
                ref="endDateMenu"
                v-model="endDateMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formattedEndDate"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="startDate"
                  :max="nowDate"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col class="d-flex" style="justify-content: flex-start; align-items: center;" sm="2">
              <v-btn
                color="success"
                @click="handelFilterLogs();"
              >
                Filter Logs
              </v-btn>
            </v-col>
          </v-row>
          <v-form ref="deleteLogsForm" autocomplete="off">
            <v-col sm="3">
              <v-text-field
                v-model="deleteLogsDays"
                :rules="rules.deleteLogsDaysRule"
                label="Delete logs older than"
                type="number"
                min="0"
                v-on:keyup="handelNagativeValue($event.target.value);"
              >
              </v-text-field>
            </v-col>
            <v-col sm="3">
              <v-btn
                color="error"
                @click="deleteLogs();"
              >
                Delete Logs
              </v-btn>
            </v-col>
            
          </v-form>
          

          <div class="pa-2">
            <v-data-table
              :headers="logsHeader"
              :items="logs"
              :items-per-page="10"
              class="elevation-1"
            >
            </v-data-table>
          </div>

        </v-card>
      </v-col>

    
    </v-row>
  </v-container>
</template>





<script>

import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";
import { mapGetters } from "vuex";
import moment from "moment";

export default {
  setup() {},
  data() {
    return {
      token: JSON.parse(localStorage.getItem("user")),
      startDateMenu: false,
      logs: [],
      filter_module: "",
      filter_status: "",
      formattedStartDate: "",
      startDate: "",
      formattedEndDate: "",
      endDate: "",
      deleteLogsDays: "",
      filterFlag: false,
      logDeleteSuccessFlag: false,
      logDeleteFailureFlag: false,
      status:['Success', 'Failure'],
      typesArr:[
        {'value': '0', 'text': "Admin user update information"},
        {'value': '1', 'text': "Portal user update information"},
        {'value': '2', 'text': "Login Admin History"},
        {'value': '3', 'text': "Login Portal History"},
        {'value': '4', 'text': "Role Admin Update"},
        {'value': '5', 'text': "Login Portal Expired"},
        {'value': '6', 'text': "Login Portal Failed"},
        {'value': '7', 'text': "Send 2fa Portal Login"},
        {'value': '8', 'text': "Login Admin Failed"},
        {'value': '9', 'text': "Create New Role"}
      ],
      types:[
        "Admin user update information",
        "Portal user update information",
        "Login Admin History",
        "Login Portal History",
        "Role Admin Update",
        "Login Portal Expired",
        "Login Portal Failed",
        "Send 2fa Portal Login",
        "Login Admin Failed",
        "Create New Role"
      ],
      rules: {
        deleteLogsDaysRule: [
            v => !!v || 'Please enter days.',
            v => !!/^[1-9][0-9]*$/.test(v) || "Please enter valid number.",
        ],
      },
      endDateMenu: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      nowDate: new Date().toISOString().slice(0, 10),
    };
  },
  watch: {
    startDate(val) {
      this.formattedStartDate = this.format_date(val);
    },
    endDate(val) {
      this.formattedEndDate = this.format_date(val);
    }
  },
  computed: {
    logsHeader() {
      return[
          { text: 'Module Name',value:'module_name'},
          //{ text: 'Execution Status', value: 'execution_status' },
          { text: 'Start Date', value: 'start_date' },
          { text: 'End Date', value: 'end_date' },
        ]
    }
  },
  beforeMount() {
    this.getLogs();
  },
  methods: {
    getLogs() {
      this.$root.isLoading = true;
      this.logs = [];
      let newStartDate = moment(this.startDate);
      let newEndDate = moment(this.endDate).add(1, 'days');
      let payload = {
        'type': this.filter_module,
        'status': this.filter_status,
        'start_date': newStartDate,
        'end_date': newEndDate,
        'filter_flag': this.filterFlag
      };
      axios.post(`${API_BASE_URL}/admin/logs`, payload, {
        headers: { 
          accept: "application/json",
          "x-access-token": this.token.accessToken,
        }
      })
      .then((resp) => {
        this.$root.isLoading = false;
        if(resp.data.length > 0) {
          resp.data.forEach((element, index) => {
            let logsObj = {};
            logsObj.module_name = this.types[element.type];
            //logsObj.execution_status = 'Success';
            logsObj.start_date = (element.created_at) ? this.formatDateTime(element.created_at) : 'N/A';
            logsObj.end_date = (element.created_at) ? this.formatDateTime(element.created_at) : 'N/A';
            this.logs.push(logsObj);
          });
        }
      });
    },
    deleteLogs() {
      if (this.$refs.deleteLogsForm.validate()) {
        if(this.deleteLogsDays > 0) {
          this.$root.isLoading = true;
          let date = moment().subtract(this.deleteLogsDays, 'days').format('YYYY-MM-DD');
          axios.delete(`${API_BASE_URL}/admin/logs/${date}`, {
            headers: { 
              accept: "application/json",
              "x-access-token": this.token.accessToken,
            }
          })
          .then((resp) => {
            this.$refs.deleteLogsForm.reset()
            if(resp.data.status === true) {
              this.logDeleteSuccessFlag = true;
              this.getLogs();
            } else {
              this.logDeleteFailureFlag = true;
            }
          });
        }
      }
    },
    handelFilterLogs() {
      this.filterFlag = true;
      this.getLogs();
    },
    formatDateTime(date) {
      return moment(date).format('MM-DD-YYYY | h:mm:ss a');
    },
    format_date(value){
      if (value) {
        return moment(String(value)).format('MM-DD-YYYY')
      }
    },
    handelNagativeValue(val) {
      if(val < 1 && val != "") {
        this.deleteLogsDays = '';
      }
    }
  }
};
</script>