import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import router from './router'
import firebase from 'firebase/app'
import store from "./store";
import './assets/app.scss'
import VueApexCharts from 'vue-apexcharts'
import VueCookies from 'vue-cookies';
import VueJsonToCsv from 'vue-json-to-csv'
import { FIRE_BASE, API_BASE_URL } from './constants';
import axios from "axios";

Vue.use(VueCookies);
Vue.use(VueApexCharts);
Vue.use(VueJsonToCsv);
Vue.component('vue-json-to-csv', VueJsonToCsv);
Vue.component('apexchart', VueApexCharts)
Vue.config.productionTip = false

const configOptions = {
  apiKey: FIRE_BASE.apiKey,
  authDomain: FIRE_BASE.authDomain,
  databaseURL: FIRE_BASE.databaseURL,
  projectId: FIRE_BASE.projectId,
  storageBucket: FIRE_BASE.storageBucket,
  messagingSenderId: FIRE_BASE.messagingSenderId,
  appId: FIRE_BASE.appId
};

firebase.initializeApp(configOptions);

firebase.auth().onAuthStateChanged(user => {
  store.dispatch("fetchUser", user);
  VueCookies.set("uid", user.uid, "7d");
});
var vm = new Vue({
  router,
  store,
  vuetify,
  data:{ //only place where data is not a function
    isLoading : false
},
watch: {
  $route: function(to, from) {
    
    var user_id = JSON.parse(localStorage.getItem("user")).id;
    var role_id = JSON.parse(localStorage.getItem("user")).role_id;

    if(!role_id)
  {
    alert("No Roles assigned yet, Please contact admin");
    return false;
  }
  axios
        .get(`${API_BASE_URL}/admin-role/${user_id}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          
          if(resp.data[0].role_id!=role_id)
          {

            localStorage.removeItem("user");
      this.$router.push("/login");
            return false;
          }});
  }
},

mounted: function () {

  
  //window.test=this;
  
//   document.body.onclick= function(e){
//     e=window.event? event.srcElement: e.target;
    
//     if(e.className && (e.className.indexOf('v-list-item__title')!=-1 ||e.className.indexOf('v-list-item primary--text v-list-item--active v-list-item--link theme--light')!=-1) )
//     {
//       window.test.$root.isLoading = true;
//     setTimeout(()=>{
//       window.test.$root.isLoading = false;
//     },1500);
//     }
//  }
},

  render: h => h(App)
}).$mount('#app')
