<template>
  <v-container class="my-7">
    <v-card class="ma-auto">
      <v-row class="mx-0">
        <v-col
          sm="4"
          cols="12"
          class="purple darken-1 pa-7 d-flex align-items-center rounded-left-xs"
        >
          <div class="ma-auto text-center white--text">
            <v-icon class="contact-icon purple--text darken-1" large
              >mdi-newspaper-variant</v-icon
            >
            <br />
            <span class="text-h6">Please fill your contact information.</span>
          </div>
        </v-col>
        <v-col sm="8" cols="12" class="px-7 py-3">
          <v-form ref="form" autocomplete="off">
            <div class="headline secondary--text font-weight-bold my-2">
              Contact Information
            </div>
            <v-row>

              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.FirstName"
                  label="First Name*"
                  required
                  :rules="rules.requiredRules"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.LastName"
                  label="Last Name*"
                  required
                  :rules="rules.requiredRules"
                  hide-details
                >
                </v-text-field>
              </v-col>

              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.email"
                  label="Email / Username*"
                  type="email"
                  disabled
                  hide-details
                >
                </v-text-field>
              </v-col>


              <v-col sm="6" cols="12">
                <v-text-field
                  v-model="contact.phone"
                  label="Phone"
                  autocomplete="off"
                  type="text"
                >
                </v-text-field>
              </v-col>
              
            </v-row>

            <!-- <v-textarea
              v-model="contact.paypal_address"
              label="PayPal email"
              auto-grow
              rows="1"
              type="text"
              row-height="15"
              autocomplete="off"
              >
            </v-textarea> -->


            <v-textarea
              v-model="contact.hnt_address"
              label="HNT"
              auto-grow
              rows="1"
              type="text"
              row-height="15"
              autocomplete="off"
            >
            </v-textarea>

            <!-- <v-text-field
              v-model="contact.eth_address"
              label="ETH"
              autocomplete="off"
              type="text"
            >
            </v-text-field> -->
            <!-- <v-text-field
              v-model="contact.btc_address"
              label="BTC"
              type="text"
              autocomplete="off"
            >
            </v-text-field> -->

            <!-- <v-text-field
              v-model="contact.zelle_address"
              label="Zelle"
              type="text"
              autocomplete="off"
            >
            </v-text-field> -->
            <!-- <v-text-field
              v-model="contact.venmo_address"
              label="Venmo"
              type="text"
              autocomplete="off"
            >
            </v-text-field> -->
            <br />

            <!-- <v-row v-if="signed == 1 && filepath">
              <v-row style="margin-left: 4px; position: relative">
                  <span style="margin-top: 14px">Download your signed contract:</span>
                  <v-btn
                    color="blue-grey"
                    class="ma-2 white--text"
                    @click="getContract()"
                  >
                    download
                    <v-icon right dark> mdi-pdf-box </v-icon>
                  </v-btn>
              </v-row>
            </v-row> -->

            <v-btn
              class="purple darken-1 white--text my-3 float-right"
              @click="save"
              >Save</v-btn
            >
            <v-row v-if="sucessAlert" style="margin-top: 24px">
              <v-alert type="success" transition="fade-transition">
                Your profile has been updated.
              </v-alert>
            </v-row>
          </v-form>
        </v-col>
        {{ info.Antenna }}
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import VueCookies from "vue-cookies";
import { API_BASE_URL } from "../../constants";
export default {
  name: "App",
  data() {
    return {
      filepath: '',
      signed: '',
      sucessAlert: false,
      uid: localStorage.getItem('user-portal-uid'),
      info: {},
      contact: {},
      rules: {
        requiredRules: [(v) => !!v || "This field is required"],
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+/.test(v) || "E-mail must be valid",
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeMount() {
    this.getuid();
    // (this.contact.name = this.user.data.displayName),
    //   (this.contact.email = this.user.data.email);
    axios
      .get(`${API_BASE_URL}/user/info/${this.uid}`, {
        headers: { accept: "application/json" },
      })
      .then((resp) => {
        try {
          (this.signed = resp.data[0].signed),
            (this.filepath = resp.data[0].filepath);
        } catch (err) {
          this.error = true;
          console.log("empty profile");
        }
      });
  },
  methods: {
    getuid() {
      axios
        .get(`${API_BASE_URL}/user/payment/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            (this.info = resp.data[0]), this.populateField(resp.data[0]);
          } catch (err) {
            console.log("empty profile");
          }
        });
    },
    populateField(arr) {

      try {
        this.contact.FirstName = arr.FirstName;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.LastName = arr.LastName;
      } catch (err) {
        console.log("empty value");
      }

      // try {
      //   this.contact.paypal_address = arr.paypal;
      // } catch (err) {
      //   console.log("empty value");
      // }
      // try {
      //   this.contact.zelle_address = arr.zelle;
      // } catch (err) {
      //   console.log("empty value");
      // }
      // try {
      //   this.contact.btc_address = arr.BTC;
      // } catch (err) {
      //   console.log("empty value");
      // }
      // try {
      //   this.contact.eth_address = arr.ETH;
      // } catch (err) {
      //   console.log("empty value");
      // }
      try {
        this.contact.hnt_address = arr.HNT;
      } catch (err) {
        console.log("empty value");
      }
      try {
        this.contact.phone = arr.Mobile;
      } catch (err) {
        console.log("empty value");
      }
      // try {
      //   this.contact.venmo_address = arr.Venmo;
      // } catch (err) {
      //   console.log("empty value");
      // }
    },
    save() {
      if (this.$refs.form.validate()) {
        console.log(this.contact);
        let payload = {
          FirstName: this.contact.FirstName,
          LastName: this.contact.LastName,
          hnt: this.contact.hnt_address,
          //paypal: this.contact.paypal_address,
          //zelle: this.contact.zelle_address,
          mobile: this.contact.phone,
          //btc: this.contact.btc_address,
          //eth: this.contact.eth_address,
          //venmo: this.contact.venmo_address,
        };
        axios.put(`${API_BASE_URL}/payment/${this.uid}`, payload),
          { headers: { "Content-Type": "application/json" } };
        this.sucessAlert = true;
      }
    },
    getContract() {
      axios
        .get(`${API_BASE_URL}/user/contract/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            console.log(resp.data);
            var id = resp.data.sid;
            window.open(`${API_BASE_URL}/download?sid=` + id, "_blank");
          } catch (err) {
            console.log("no contract signed");
          }
        });
    },
  },
};
</script>
<style>
.contact-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  margin: 20px;
}
</style>