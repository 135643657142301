<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-card class="pa-3 purple darken-1">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Rewards
            </div>
            <v-dialog v-model="twofactor" persistent width="500">
              <v-card>
                <v-card-title class="headline purple darken-1 white--text">
                  Two Factor Autentication is Needed
                </v-card-title>
                <v-row>
                  <v-col> </v-col>
                </v-row>
                <v-card-text class="pa-3">
                  <div>
                    <span>
                      Scan this QR code with Google Autenticator and do not
                      delete the app. If you remove that you will not be able to
                      restore your code.
                    </span>
                    <v-container
                      ><v-row align="center" justify="center">
                        <template>
                          <figure>
                            <vue-qrcode
                              :value="this.authkey"
                              :options="{
                                errorCorrectionLevel: 'Q',
                                width: 200,
                                height: 200,
                              }"
                            ></vue-qrcode>
                          </figure>
                        </template>
                        <v-btn icon color="green" @click="getNewQR()">
                          <v-icon>mdi-cached</v-icon>
                        </v-btn>
                      </v-row>
                      <v-row align="center" justify="center">
                        <span>
                          Please enter the OTP here to check your settings
                        </span></v-row
                      >
                      <v-row align="center" justify="center">
                        <CodeInput :key="componentKey"
                          :loading="false"
                          :autoFocus="true"
                          class="input"
                          v-on:change="onChange"
                          v-on:complete="onComplete"
                        />
                      </v-row>
                      <br /><br /><v-alert type="success" v-model="correcttoken"
                        >The Generated Token is Correct</v-alert
                      >
                      <v-alert type="error" v-model="errortoken"
                        >The Generated Token is Incorrect</v-alert
                      ><br />
                      <!--<v-row align="end" justify="end">
                        <v-btn color="success" @click="SaveToken()"
                          >SAVE</v-btn
                        >
                        </v-row
                      >-->
                    </v-container>
                  </div>
                </v-card-text>
              </v-card>
            </v-dialog>
                                    <v-dialog v-model="dialog" width="500">
              <v-card>
                <v-card-title class="headline purple darken-1 white--text">
                  Request Cashback
                </v-card-title>
                <v-card-text class="pa-3">
                  <div>
                    Please send an email to
                    <a href="mailto: payments@jagindustrials.com"
                      >payments@jagindustrials.com</a
                    >
                    with
                    subject title “Payment Request - your name” from the email
                    associated with this account. In your email, list the amount
                    you wish to withdraw with your full legal name and payment
                    method (HNT wallet, BTC wallet, ETH wallet, Paypal, Venmo or
                    Zelle). Please ensure your designated address is correct in
                    your profile prior to requesting a cash out. Note payments
                    may take a few days to process.
                    <!--<v-row >
                                        <input
                                                v-model="amount"
                                                color="#474DFF"
                                                filled
                                                :max= 'maxearn'
                                                clearable
                                                label="Enter amount"
                                                type="number"
                                        >
                                                <v-select
                                                :items="items"
                                                label="Payment method"
                                                v-model="message"
                                                :append-outer-icon="message ? 'mdi-send' : 'mdi-send'"
                                                @click:append-outer=sendRequest(amount,message)
                                                ></v-select>
                                        </v-row>-->
                  </div>
                </v-card-text>
                <!--<p>MAX amount available for withdrawal {{  maxearn }} HNT</p>
                               <p> selected Payment Method {{message}} </p>-->
              </v-card>
            </v-dialog>
           
           <!-- <v-btn
              outlined
              dark
              small
              v-bind="attrs"
              v-on="on"
              @click="newRequest()"
              :class="requestPaymentButton == false ? 'disabled' : ''"
            >
              Request Cashback
            </v-btn> -->

            <v-dialog v-model="dialog2" width="500">
              <v-card>
                <v-card-title class="headline purple darken-1 white--text">
                  Request Cashback
                </v-card-title>
                <v-card-text class="pa-3">
                  <div>
                    <span v-if="message == 'Zelle'">
                    Please send an email to
                    <a href="mailto: payments@jagindustrials.com">payments@jagindustrials.com</a>
                    with subject title “Payment Request - your name” from the email associated with this account.
                     In your email, list the amount you wish to withdraw with your full legal name and payment method (HNT wallet, BTC wallet, ETH wallet, Paypal, Venmo or Zelle). Please ensure your designated address is correct in your profile prior to requesting a cash out. Note payments may take a few days to process.
                    </span>

                    <span v-if="message != 'Zelle' && message != 'Venmo'">
                      Please ensure your designated address is correct in your profile prior to requesting a cash out.
                      JAG is not
                      responsible for incorrect wallet addresses. Click
                      <router-link :to="'/contact'" class="menu-item"
                        >here</router-link
                      >
                      to validate.
                      Note payments may take a few days to process.
                    </span>

                    <v-container>
                    <v-row >
                      <v-text-field
                        v-model="email"
                        label="Email"
                        type="text"
                        readonly
                        disabled
                      ></v-text-field>
                    </v-row>
                    <v-row v-if="message != 'Zelle'">
                      <v-text-field
                        v-model="amount"
                        label="Enter HNT amount"
                        type="number"
                        min="1"
                      ></v-text-field>
                    </v-row>
                    <v-row>
                        <v-select
                          :items="items"
                          label="Payment method"
                          v-model="message"
                          @change="changeAlert()"
                        ></v-select>
                      </v-row>
                      <v-row v-if="message != 'Zelle'">
                        <v-btn color="success" @click="sendRequest()">Submit</v-btn>
                      </v-row>
                      <v-row v-if="alert" style="margin-top: 24px">
                        <v-alert type="error" transition="fade-transition">
                         {{errorMsg}}
                        </v-alert>
                      </v-row>
                      <v-row v-if="sucessAlert" style="margin-top: 24px">
                        <v-alert type="success" transition="fade-transition">
                          Thank you for submitting your request. Your account will be updated upon approval of the request.
                        </v-alert>
                      </v-row>
                    </v-container>
                  </div>
                </v-card-text>
                <!--<p>MAX amount available for withdrawal {{  maxearn }} HNT</p>
                               <p> selected Payment Method {{message}} </p>-->
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </v-col>

      <v-col  cols="12">
        <v-alert type="info">
          Please send an email to
          <a href="mailto: payments@jagindustrials.com">
          payments@jagindustrials.com
          </a>
          with
          subject title “Payment Request - your name” from the email
          associated with this account. In your email, list the amount
          you wish to withdraw with your full legal name and payment
          method (HNT wallet, BTC wallet, ETH wallet, Paypal, Venmo or
          Zelle). Please ensure your designated address is correct in
          your profile prior to requesting a cash out. Note payments
          may take a few days to process.
        </v-alert>
      </v-col>

      <v-dialog v-model="signneed" persistent max-width="450">
        <v-card>
          <v-card-title class="text-h5">
            New contract awaiting signature.
          </v-card-title>
          <v-card-text
            >A new hotspot has been assigned to you. Please sign the contract to
            see the hotspot details.</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
           <!-- <v-btn color="purple darken-1" text @click="signneed = false">
              Close
            </v-btn>-->
            <v-btn color="purple darken-1" text to="/sign"> Go to sign </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-col md="4" cols="12">
        <v-card class="my-2 border-left-4">
          <div>
            <div>
              <v-card-subtitle class="pb-0"> Total HNT mined </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                {{ remainbalance.toFixed(2) }} HNT - ${{
                  (
                    (oracleprice.data.price / 100000000) *
                    remainbalance
                  ).toFixed(2)
                }}
              </v-card-title>
              <v-card-title class="text-h6 gold_4--text"> </v-card-title>
            </div>
          </div>
        </v-card>
        <v-card class="my-2 border-left-4">
          <div>
            <div>
              <v-card-subtitle class="pb-0">
                Remain HNT to withdrawal
              </v-card-subtitle>
              <v-card-title class="text-h6 gold_4--text">
                {{ (remainbalance + alreadypayed).toFixed(2) }} HNT - ${{
                  (
                    (oracleprice.data.price / 100000000) *
                    (remainbalance + alreadypayed)
                  ).toFixed(2)
                }}
              </v-card-title>
              <v-card-title class="text-h6 gold_4--text"> </v-card-title>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col md="8" cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Transfer activity
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <div class="pa-2">
            <v-data-table
              :headers="headers"
              :items="requestLog"
              item-key="name"
              class="elevation-1"
              :search="search"
              :custom-filter="filterOnlyCapsText"
            >
              <template v-slot:body.append> </template>
              <template v-slot:item.entered="{ item }">
                <span>{{ new Date(item.entered).toLocaleString() }}</span>
              </template>
              <template v-slot:item.HNTchange="{ item }">
                <span>{{ item.HNTchange }} HNT</span>
              </template>
            </v-data-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
import { API_BASE_URL, API_HELIUM_URL } from "../../constants";

export default {
  setup() {},
  data() {
    return {
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      message: "HNT",
      amount: 0,
      twofactor: false,
      dialog: false,
      hotspotDetails: [],
      hotspotTotal: [],
      errortoken: false,
      url: "",
      authkey: "",
      mapurl: "",
      remainbalance: 0,
      start: "",
      hname: "",
      correcttoken: false,
      search: "",
      alreadypayed: 0,
      commission: 0,
      signneed: false,
      first30days: 0,
      arr: [],
      today: "",
      uid: localStorage.getItem('user-portal-uid'),
      oracleprice: {
        data: ''
      },
      installation: "",
      requestLog: [],
      maxearn: 0,
      authcomplete: null,
      email: "",
      userInfo: {},
      alert: false,
      sucessAlert: false,
      errorMsg: "",
      componentKey: 0,
      attrs: '',
      on: '',
      lastLastPaymentDate:'',
      requestPaymentButton:false
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
    headers() {
      return [
        //{ text: "Amount", value: "amount" },
        //{ text: "Currency From", value: "HNTchange" },
        { text: "Amount", value: "HNTchange" },
        { text: "Currency To", value: "payment" },
        {
          text: "Status",
          value: "flag",
        },
        {
          text: "Date Completed",
          align: "start",
          value: "entered",
        },
      ];
    },
  },
  beforeMount() {
    this.getOracleValue();
    this.getuid();
    this.getRequest();
    let authUser = localStorage.getItem("user");
    authUser = JSON.parse(authUser);
    this.email = authUser.email;
  },
  methods: {
        forceRerender() {
      this.componentKey += 1;
    },
    getNewQR() {
      window.location.reload();
    },
    onChange(v) {
      this.correcttoken = false;
      this.errortoken = false;
    },
    onComplete(v) {
      var data = {
        token: v,
      };
      axios
        .post(`${API_BASE_URL}/user/token/${this.uid}`, data, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          console.log(resp, data);
          this.errortoken = false;
          this.forceRerender()
          if (resp.data == true) {
            this.correcttoken = true;
            this.twofactor = false;
            this.dialog = true;
            this.correcttoken = false;
          } else {
            this.errortoken = true;
          }
        });
    },
    newRequest() {
      this.dialog = true
    },
    newRequest2() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            if (resp.data[0]["2fa"] == null) {
              this.generateQrCode();
              this.twofactor = true;
            } else {
              this.twofactor = true;
            }
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
    },
    getOracleValue() {
      axios
        .get(`${API_HELIUM_URL}/v1/oracle/prices/current`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.oracleprice = resp.data;
        });
    },
    SaveToken() {
      this.twofactor = false;
      this.dialog = true;
    },
    getuid() {
      this.getglobal();
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          this.parsingName(resp.data);
        });
    },
    generateQrCode() {
      axios
        .get(`${API_BASE_URL}/user/2fa/${this.uid}`, {
          headers: {
            accept: "application/json",
          },
        })
        .then((resp) => {
          console.log(resp);
          this.authkey = resp.data;
        });
    },
    parsingName(arr3) {
      var arr1 = [];
      var arr2 = [];
      let promises = [];
      arr3.forEach((element) => {
        promises.push(
          axios.get(`${API_HELIUM_URL}/v1/hotspots/${element.Haddress}`, {
            headers: { accept: "application/json" },
          })
        );
      });
      Promise.all(promises).then((results) => {
        results.forEach((resp) => {
          arr2.push(resp.data.data);
          arr1.push(resp.data.data.name);
        });
        this.remainpayment(arr1, arr2, arr3);
      });
    },
    remainpayment(arr1, arr2, arr3) {
      this.ownedhotspot = arr1; //name hotspot
      this.detailhotspot = arr2; //helium data
      this.hotspotAll = arr3; //jag api data
      var i;
      var app;
      var conc = {};
      let multipleSum = 0;
      let details = this.detailhotspot;
      this.remainbalance = 0;
      for (i = 0; i < details.length; i++) {
        for (var attrname in this.hotspotAll[i]) {
          conc[attrname] = this.hotspotAll[i][attrname];
        }
        for (var attrname1 in details[i]) {
          conc[attrname1] = details[i][attrname1];
        }
        this.hotspotAll[i] = conc;
        conc = {};
      }
      this.hotspotAll.forEach((element) => {
        //jag value + helium value
        var endinst = null;
        var [year, month, day] = element.timestamp_added
          .substring(0, 10)
          .split("-");

        var installation = new Date(year, month - 1, day);

        if (element.installDate != null) {
          this.installdatetime = element.installDate.slice(0, 10);
        } else {
          this.installdatetime = installation.toISOString().substring(0, 10);
        }
        if (element.removeDate != null) {
          endinst = "&max_time=" + element.removeDate.slice(0, 10);
        }
        this.installdatetime = this.checkfirstdays(
          element.role,
          this.installdatetime,
          element.firstdays
        );
        if (element.role == 1) {
          //if refer owner
          if (element.firstdays == 0) {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              "refer"
            );
          } else {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              element.commission,
              "refer"
            );
          }
        }
        if (element.role == 0) {
          //if primary owner
          if (this.first30days == 1) {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              "primary"
            );
          } else {
            this.getbalance(
              element.address,
              this.installdatetime,
              endinst,
              this.commission,
              "primary"
            );
          }
        }
      });
    },

    checkfirstdays(role, startdate, firstdays) {
      var [year, month, day] = startdate.substring(0, 10).split("-");
      var installation = new Date();
      if (role == 1) {
        if (firstdays == 0) {
          //30 days NO
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        }
      } else {
        //primary owner
        if (this.first30days == 0) {
          //30 days YES
          installation = new Date(year, month, day);
          installation.setDate(installation.getDate() + 1);
        } else {
          installation = new Date(year, month - 1, day);
          installation.setDate(installation.getDate() + 1);
        }
      }
      this.installation = installation.toISOString().substring(0, 10);
      return installation.toISOString().substring(0, 10);
    },
    getglobal() {
      axios
        .get(`${API_BASE_URL}/user/info/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          try {
            if(resp.data[0].investor == 0){
               if(resp.data[0].signed==1 || resp.data[0].signed==null){this.signneed=false}
              else{
                 //this.signneed=true //we are moving this functionality to shopify
                }
              (this.commission = resp.data[0].commission),
                (this.first30days = resp.data[0].FirstDays);
            } 
            if (resp.data[0]["2fa"] == null) {
              this.generateQrCode();
            }
          } catch (err) {
            this.error = true;
            console.log("empty profile");
          }
        });
    },
    getbalance(address, dateinst, endinst, commission, type) {
      var final_comm = 0;
      if (type == "refer") {
        axios
          .get(`${API_BASE_URL}/user/hotspot/own/${address}`, {
            headers: {
              accept: "application/json",
            },
          })
          .then((resp) => {
            if (resp.data.length != 0) {
              final_comm = resp.data[0].commission;
            } else {
              final_comm = 20;
            }
            this.getbalanceStep2(
              address,
              dateinst,
              endinst,
              commission,
              final_comm
            );
          });
      } else {
        this.getbalanceStep2(
          address,
          dateinst,
          endinst,
          commission,
          final_comm
        );
      }
    },
    getbalanceStep2(address, dateinst, endinst, commission,final_comm) {
      if (endinst == "null" || endinst == null) {
        endinst = "";
      }
      axios
        .get(
          `${API_HELIUM_URL}/v1/hotspots/${address}/rewards/sum?min_time=${dateinst.slice(
            0,
            10
          )}${endinst}`,
          {
            headers: {
              accept: "application/json",
            },
          }
        )
        .then((resp) => {
          this.remainbalance +=
            ((resp.data.data.total -
              (resp.data.data.total / 100) * final_comm) /
              100) *
            commission;
          // console.log( "total",resp.data.data.total,"final comm",final_comm,"commisison",commission,"addr",address);
        })
        .catch((resp) => {
          this.remainbalance += 0;
        });
    },
    getRequest() {
      axios
        .get(`${API_BASE_URL}/user/request/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.requestLog = resp.data;
          for (var i in this.requestLog) {
            if (this.requestLog[i].payment == "HNT") {
              this.requestLog[i].HNTchange = this.requestLog[i].amount;
            }
            if (this.requestLog[i].flag == 0) {
              this.requestLog[i].flag = "Pending";
            } else if (this.requestLog[i].flag == 1) {
              this.requestLog[i].flag = "Completed";
              this.alreadypayed -= parseFloat(this.requestLog[i].HNTchange);
            } else if (this.requestLog[i].flag == 2) {
              this.requestLog[i].flag = "Deleted";
            } else if (this.requestLog[i].flag == 3) {
              this.requestLog[i].flag = "Approved";
            }
          }
                    //get last trans date for request payement button 
          var transLen = this.requestLog.length;
          this.lastLastPaymentDate = this.requestLog ? this.requestLog[transLen-1].entered.substring(0, 10):'';
          var today = new Date();
          var lastDate = new Date(this.lastLastPaymentDate);
          var diffTime = Math.abs(today - lastDate);
          var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
          console.log(diffDays + " days");
          
          //allow request payment if last trans diff is 30 days
          if(diffDays>=30){
            this.requestPaymentButton = true;
          }
          //end
        });
    },
    sendRequest() {
      this.sucessAlert = false;
      this.alert = false;
      this.errorMsg = '';
      let errorFound = false;
    
      if(this.amount < 1){
        this.alert = true;
        this.errorMsg = 'HNT amount should be greater than 0.'
        return false;
      }

      //limit HNT for withdraw
      let maxHNTWithdraw = (this.remainbalance + this.alreadypayed).toFixed(2);
      if(maxHNTWithdraw < 0 || this.amount > maxHNTWithdraw) {
        this.alert = true;
        this.errorMsg = 'There is an insufficient amount of HNT to withdraw.'
        return false;
      }


      //check if selected payment address has empty value
      if(this.message == 'HNT' && !this.userInfo.HNT){
        this.alert = true;
        errorFound = true;
      }
      else if(this.message == 'BTC' && !this.userInfo.BTC){
        this.alert = true;
        errorFound = true;
      }
      else if(this.message == 'ETH' && !this.userInfo.ETH){
        this.alert = true;
        errorFound = true;
      }
      else if(this.message == 'PayPal' && !this.userInfo.paypal){
        this.alert = true;
        errorFound = true;
      }
      else if(this.message == 'Venmo' && !this.userInfo.venmo){
        this.alert = true;
        errorFound = true;
      }
      
      if(errorFound){
        this.errorMsg = 'Selected address is not available please check Your Profile wallets.'; 
        return false;
      }

      axios.post(`${API_BASE_URL}/user/request/${this.uid}`, {
        payment: this.message,
        amount: this.amount,
      });
      this.sucessAlert = true;
      // this.dialog = false;
      this.getRequest();
    },
    filterOnlyCapsText(value, search, item) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getUserInfo() {
      axios.get(`${API_BASE_URL}/user/payment/${this.uid}`, {
          headers: {'accept': 'application/json'}
      }).then((resp) => {
          try {   
                  this.userInfo = resp.data[0];
          } catch (err) {
              console.log('empty profile')
          }
      })
    }
  },
};
</script>
<style scoped>
.qrcode {
  display: inline-block;
  font-size: 0;
  margin-bottom: 0;
  position: relative;
}

.qrcode__image {
  background-color: #fff;
  border: 0.25rem solid #fff;
  border-radius: 0.25rem;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.25);
  height: 15%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 15%;
}

.v-btn.disabled{
    opacity: 0.6;
    cursor: not-allowed;
    pointer-events: none;
}
</style>