<template>
  <v-container>
    <v-row>

      <v-col cols="12">
         <v-card class="pa-3 blue darken-4">
          <div class="d-flex justify-space-between align-center">
            <div class="text-sm-h5 text-xs-h6 font-weight-bold white--text">
              Black List
            </div>
            <v-btn outlined dark small @click="openWalletModal()">
              Add Wallet
            </v-btn>
          </div>
        </v-card>
      </v-col>

      <v-col md="12 " cols="12" class="my-2">
        <v-card class="pa-2" style="height: 100%" min-height="300px">
          <v-card-title>
            Black List
            <v-spacer></v-spacer>
          </v-card-title>

          <div class="pa-2">
            <v-data-table
              :headers="headers1"
              :items="wallets"
              :items-per-page="5"

              class="elevation-1"
            >
              <template v-slot:item.name="{ item }">
                {{item.name}}
              </template>
              
               <template v-slot:item.action="{ item }">
                <v-btn
                  fab
                  dark
                  small
                  color="red accent-4"
                  @click="showDeleteConfirmation(item.id)"
                  ><v-icon dark> mdi-delete </v-icon>
                </v-btn>
              </template>

            </v-data-table>
          </div>

        </v-card>
      </v-col>

      <v-dialog
          v-model="blackListModal"
          persistent
          max-width="1200px"
          height="1000px">
        <v-card>
          <v-card-title class="headline blue darken-4 white--text">
            Add Wallet
          </v-card-title>
          <v-form ref="form5" class="mx-2" lazy-validation>
            <v-card-text class="pa-5">
              <div>
                <v-row>
                  <v-col>
                    <v-text-field
                      v-model="name"
                      filled
                      clearable
                      label="Wallet Address"
                      type="text"
                      :rules="rules.hntAddressRules"
                    ></v-text-field> </v-col
                  >
                </v-row>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <v-btn text dark color="red" @click="closingRequest()">
                    Close
                  </v-btn>
                  <v-btn
                    text
                    dark
                    color="success"
                    @click="addWallet()"
                  >
                    Save
                  </v-btn>
                </v-card-actions>

              </div>
            </v-card-text>
          </v-form>
        </v-card>
      </v-dialog>
    </v-row>

     <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="reject" :retain-focus="false" max-width="350">
      <v-card>
        <v-card-title class="text-h5">
          Are you sure you want to remove this wallet?
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="reject = false">
            NO
          </v-btn>
          <v-btn
            color="error"
            text
            @click="deleteWallet()"
          >
            YES
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>



<script>


import axios from "axios";
import { API_BASE_URL } from "../constants";

export default {
  setup() {},
  data() {
    return {
    permissions:[],
    reject: false,
    selectedRejectId: '',
      items: ["ETH", "BTC", "HNT", "Zelle", "PayPal", "Venmo"],
      text: '',
      timeout: 4000,
      wallets: [{"name":'Super Admin',"action":"action"}],
      editTemplate:false,
      blackListModal:false,
      token: JSON.parse(localStorage.getItem("user")),
      search: "",
      uid: "",
      attrs: '',
      on: '',
      templateID:0,
      ename:"",
      name:"",
      esubject:"",
      ebody:"",
      content: '',
      config: {
        toolbarGroups: [
            { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
            { name: 'forms', groups: [ 'forms' ] },
            { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
            { name: 'paragraph', groups: [ 'list',  'align',  'paragraph' ] },
            
            { name: 'styles', groups: [ 'styles' ] },
            { name: 'colors', groups: [ 'colors' ] },
            { name: 'others', groups: [ 'others' ] }
          ],
        height: 300
      },
      rules: { 
        requiredRules: [(v) => !!v || "This field is required"],
        hntAddressRules:[
          v => !!v || 'HNT wallet address is required',
            v => !!/^.[a-zA-Z0-9]{50,50}$/.test(v) || 'Please enter valid 51 characters HNT wallet address.',
        ]
      },
    };
  },
  computed: {
    headers1() {
      
      return[
          { text: 'Wallet',value:'wallet'},
          { text: 'Action', value: 'action' },
        ]
    }
  },
  beforeMount() {
    this.getBlackList();
  },

  methods: {
    getuid() {
      axios
        .get(`${API_BASE_URL}/user/hotspot/${this.uid}`, {
          headers: { accept: "application/json" },
        })
        .then((resp) => {
          this.hotspotDet(resp.data[0].Haddress, this.start, this.today);
        });
    },
    filterOnlyCapsText(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "string" &&
        value.toString().toLocaleLowerCase().indexOf(search) !== -1
      );
    },
    getBlackList(){
      this.$root.isLoading = true;
       this.wallets = [];
        axios
        .get(`${API_BASE_URL}/admin/blackLists`, {
          headers: {  accept: "application/json",
            "x-access-token": this.token.accessToken, },
        })
        .then((resp) => {
          console.log(resp.data)
          this.$root.isLoading = false;
          this.wallets = resp.data;
        }).catch((err) => {
          console.log(err);
          this.$root.isLoading = false;
        });

     
    },
    deleteWallet(){
      axios.delete(`${API_BASE_URL}/admin/blackLists/${this.selectedRejectId}`, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          setTimeout(() => {
            this.reject = false;
            this.selectedRejectId = '';
          }, 500)
          this.text = "Wallet deleted successfully.";
          this.snackbar = true
          this.closingRequest();
          this.getBlackList();
        });
    },
    addWallet(){
       if(!this.$refs.form5.validate()){
        return false;
       }
       
       if (!this.name)
       {
         return false;
       }

        let payload = {
          wallet: this.name
        };
        axios.post(`${API_BASE_URL}/admin/blackLists`, payload, {
          headers: {
            accept: "application/json",
            "x-access-token": this.token.accessToken,
          },
        })
        .then((resp) => {
          this.name = '';
          this.text = "Wallet added successfully.";
          this.snackbar = true
          this.closingRequest();
          this.getBlackList();
        });
     
    },
    showDeleteConfirmation(selectedId){
      this.reject = true;
      this.selectedRejectId = selectedId;
    },
    openWalletModal(){
      this.blackListModal = true;
    },
    closingRequest(){
      this.editTemplate = false;
      this.blackListModal = false;
      (this.ename = ''),
      (this.esubject = ''),
      (this.ebody ='');
    },
    formatHtmlText(str) {
      if ((str===null) || (str===''))
      return false;
      else
      str = str.toString();
      return str.replace( /(<([^>]+)>)/ig, '');
   }
  },
};
</script>