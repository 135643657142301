<template>
  <v-row class="ma-0">
    <v-col
      sm="6"
      cols="12"
      class="blue darken-4 pa-7 d-flex align-items-center hv-sm-100"
    >
    </v-col>
    <v-col sm="6" cols="12" class="px-7 py-3 hv-sm-100 d-flex">
      <v-card
        class="ma-auto"
        elevation="0"
        :width="$vuetify.breakpoint.smAndUp ? '70%' : '85%'"
      >
        <v-alert
          dense
          outlined
          color="error"
          class="my-5"
          dismissible
          v-if="errorFlag"
        >
          {{errorMessage}}
        </v-alert>
        <v-alert
          dense
          outlined
          color="success"
          class="my-5"
          dismissible
          v-if="successFlag"
        >
          {{successMessage}}
        </v-alert>
        <v-form ref="resetPasswordForm" class="text-center" @submit="resetPassword">
          <div class="headline secondary--text font-weight-bold my-2" v-if="this.$route.params.flag == 'reset'">
            Reset Password
          </div>
          <div class="headline secondary--text font-weight-bold my-2" v-else>
            Create Password for New Account
          </div>
          <v-text-field
            v-model="form.username"
            label="Username"
            class="my-5"
            disabled
          >
          </v-text-field>
          <v-text-field
            v-model="form.newPassword"
            label="Password*"
            type="password"
            class="my-5"
            required
            v-on:keyup.enter="resetPassword"
            :rules="[rules.required, rules.min]"
            :disabled="disabledFlag"
          >
          </v-text-field>
          <v-btn class="blue darken-4 white--text my-3" @click="resetPassword" :disabled="disabledFlag" v-if="this.$route.params.flag == 'reset'">
            Reset Password
          </v-btn>
          <v-btn class="blue darken-4 white--text my-3" @click="resetPassword" :disabled="disabledFlag" v-else>
            Create Password
          </v-btn>
          <br/>
          <a @click="goToLogin();">Click here to login.</a>
        </v-form>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import firebase from "firebase";
import { mapGetters } from "vuex";
import axios from "axios";
import { API_BASE_URL, API_HELIUM_URL } from "../constants";

const API_URL = `${API_BASE_URL}/api/auth/`;
export default {
  name: "App",
  data: () => ({
    form: {
      id: "",
      username: "",
      newPassword: "",
      two_fa:  ""
    },
    errorFlag: false,
    successFlag: false,
    disabledFlag: true,
    errorMessage: '',
    successMessage: '',
    twofactor: false,
    twofactorError: false,
    twofactorErrorMsg: '',
    error: null,
    rules: {
      required: value => !!value || 'Please enter password.',
      min: v => v.length >= 8 || 'Min 8 characters',
      //passwordMatch: () => (`The email and password you entered don't match`),
    },
  }),
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },
  beforeMount() {
    this.getUserInfo();
  },
  methods: {
    clearForm() {
      this.$refs.resetPasswordForm.reset()
    },
    getUserInfo() {
      let token = this.$route.params.token;
      try {
        let payload = {
          token: token
        };
        axios.get(`${API_BASE_URL}/reset/password/user/info/${token}`, {
          headers: {
            accept: "application/json",
            //"x-access-token": this.token.accessToken,
          },
        })
        .then((response) => {
          let result = response.data;
          if(result.status === true) {
            this.disabledFlag = false;
            this.form.id = result.data.id;
            this.form.username = result.data.username;
          } else {
            this.clearForm();
            this.errorMessage = "Link has been expired."
            this.errorFlag = true;
            this.disabledFlag = true;
          }
        })
        .catch((error) => {
          this.clearForm();
          this.errorMessage = "Opps, Something went wrong."
          this.errorFlag = true;
          this.disabledFlag = true;
        });
      } catch(error) {
        this.clearForm();
        this.errorMessage = "Opps, Something went wrong."
        this.errorFlag = true;
        this.disabledFlag = true;
      }
    },
    resetPassword() {
      try {
        let validateFlag = this.$refs.resetPasswordForm.validate();
        if(validateFlag) {
          let payload = {
            id: this.form.id,
            password: this.form.newPassword,
          };
          axios.post(`${API_BASE_URL}/reset/password`, payload, {
            headers: {
              accept: "application/json",
              //"x-access-token": this.token.accessToken,
            },
          })
          .then((response) => {
            this.clearForm();
            if(response.data.status === true) {
              if(this.$route.params.flag == 'reset') {
                this.successMessage = "Password has been reset successfully.";
              } else {
                this.successMessage = "New password has been created successfully.";
              }
              
              this.successFlag = true;
              this.disabledFlag = true;
            } else {
              if(this.$route.params.flag == 'reset') {
                this.errorMessage = "Opps, Something went wrong, Unable to reset password.";
              } else {
                this.errorMessage = "Opps, Something went wrong, Unable to create new password.";
              }
              this.errorFlag = true;
              this.disabledFlag = true;
            }
          })
          .catch((error) => {
            if(this.$route.params.flag == 'reset') {
                this.errorMessage = "Opps, Something went wrong, Unable to reset password.";
              } else {
                this.errorMessage = "Opps, Something went wrong, Unable to create new password.";
              }
            this.errorFlag = true;
            this.disabledFlag = true;
          });
        }
      } catch(error) {
        console.log(error);
      }
    },
    goToLogin() {
      this.$router.replace({ name: "login" });
    }
  },
};
</script>
<style>
.contact-icon {
  background: white;
  border-radius: 50%;
  padding: 10px;
  margin: 20px;
}
</style>